<template>
  <v-dialog
    v-model="show"
    persistent
    :width="850"
    overlay-opacity=".97"
    :fullscreen="isPhone"
    @keydown.esc="cancel"
    eager
  >
    <v-card
      tile
      flat
      class="charcoal noscroll-x d-flex flex-column"
      v-touch:swipe.left="cancel"
    >
      <BaseCloseDialogHeader heading="Select & Start" @cancel="cancel" />
      <v-card-title :class="textSize + ' pa-2 paper--text'">
        {{ name }}
      </v-card-title>
      <template v-if="variations.length > 0">
        <v-card-subtitle :class="textSizeXSmall + ' silver--text px-2 py-4'">
          This workout has variations. You can select a variation below or start
          the original workout.
        </v-card-subtitle>
        <v-card-text :class="textSizeSmall + ' silver--text px-2 pb-0'">
          Select A Workout Variation
        </v-card-text>
        <v-card-text
          :class="textSizeSmall + ' paper--text link py-1 mb-1'"
          @click="selectedVariation = null"
        >
          <v-icon  size="30" class="mt-n1 progressActive--text">
            {{
              selectedVariation == null
                ? 'mdi-checkbox-marked-outline'
                : 'mdi-checkbox-blank-outline'
            }}
          </v-icon>
          Original Workout
        </v-card-text>
        <v-list
          dense
          v-if="selectedVariation == null"
          class="ml-3 py-0 mt-n3 pl-5 transparent"
        >
          <v-list-item
            v-for="exercise in workout_exercises"
            :key="exercise.id"
            class="pl-1 py-0"
          >
            <v-row dense>
              <v-col cols="12">
                <v-badge
                  color="progressActive charcoal--text"
                  left
                  inline
                  :content="exercise.sort"
                >
                  <div
                    :class="textSizeXSmall + ' ml-1 silver--text link'"
                    :title="exercise.name"
                  >
                    {{ exercise.name }}
                  </div>
                </v-badge>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
        <v-card-text
          v-for="(variation, index) in variations"
          :key="index"
          :class="textSizeSmall + ' paper--text link py-1 mb-2'"
          @click="selectedVariation = variation"
        >
          <v-icon size="30" class="mt-n1 progressActive--text">
            {{
              selectedVariation == variation
                ? 'mdi-checkbox-marked-outline'
                : 'mdi-checkbox-blank-outline'
            }}
          </v-icon>
          {{ variation.variation_name }}
          <v-list
            dense
            v-if="selectedVariation == variation"
            class="ml-3 py-0 transparent"
          >
            <v-list-item
              v-for="exercise in JSON.parse(variation.variant_exercises)"
              :key="exercise.id"
              class="pl-1 py-0 "
            >
              <v-row dense>
                <v-col cols="12">
                  <v-badge
                    color="progressActive charcoal--text"
                    left
                    inline
                    :content="exercise.sort"
                  >
                    <div
                      :class="textSizeXSmall + ' ml-1 silver--text  link'"
                      :title="exercise.name"
                    >
                      {{ exercise.name }}
                    </div>
                  </v-badge>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list>
        </v-card-text>
      </template>
      <template v-else>
        <v-sheet class="mx-2 mt-2 pa-2 charcoal charcoalTile">
          <v-card-text class="caption pa-0 silver--text">
            <v-icon color="silver">
              mdi-information
            </v-icon>
            You have no variations for this workout. Variations are "equivalent
            workouts".
            <v-spacer /><br />
            You can set-up workout variations when editing a workout by
            selecting "Manage Variations".
            <v-spacer />
            Every time you start a workout that has defined variations, you get
            to choose which workout you will start.
          </v-card-text>
        </v-sheet>
      </template>
      <v-spacer />
      <v-sheet
        rounded
        class="transparent charcoalTileMenu"
        @click="startWorkout"
      >
        <v-btn
          tile
          block
          height="90"
          title="Save and Close"
          tonal
          class=" charcoal--text "
          color="progressActive"
        >
          <v-icon x-large>
            mdi-play-circle
          </v-icon>

          <span :class="textSize">
            Start Now
          </span>
        </v-btn>
      </v-sheet>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from 'axios'
import { appConfig, builder } from '@/store/helpers.js'
import util from '@/mixins/util.js'

export default {
  components: {},
  mixins: [util],
  name: 'StartWorkoutDialog',
  data: () => ({
    workouts: [],
    variations: [],
    workout_exercises: [],

    selectedVariation: null,
    programInstance: {},
    loaded: false,
    search: null,
    switchNum: 0
  }),
  mounted() {
    window.addEventListener('message', this.receiveMessage)
  },
  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessage)
  },
  beforeMount() {
    this.loaded = false
  },
  props: {
    show: {
      type: Boolean
    },
    programid: {
      type: String
    },
    workout: {
      type: Object
    }
  },
  watch: {
    show: function(value) {
      this.$store.dispatch('session/toggleModal', value)
    },
    workout: function() {
      this.loadVariations()
    }
  },
  computed: {
    ...appConfig,
    ...builder,
    name() {
      return this.workout.hasOwnProperty('name')
        ? this.workout.name
        : this.workout.workout_name
    }
  },
  methods: {
    receiveMessage(event) {
      if (event.data.type == 'CLOSE_MODAL' && event.data.key == 'UoMc92xqvA') {
        this.cancel()
      }
    },


    cancel() {
      this.$emit('cancel')
    },
    loadVariations() {
      let url = '/workout/variations/'
      return axios
        .get(this.baseURL + url + this.workout.workoutid, {})
        .then(response => {
          if (response.status == 200) {
            this.variations = response.data.data
            if (this.variations.length > 0) {
              this.workout_exercises = JSON.parse(
                this.variations[0].workout_exercises
              )
            }
            this.selectedVariation = null
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    startWorkout() {
      localStorage.setItem('startWorkout', true)
      return axios
        .post(this.baseURL + '/program/workout/start', {
          programworkoutinstanceid: this.workout.workoutid,
          skip: false,
          from_workout: true
        })
        .then(response => {
          if (response.status == 200) {
            let useVariation = this.selectedVariation != null
            let startWhat = useVariation ? this.selectedVariation : this.workout
            this.goTo(startWhat, useVariation)
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    goTo(workout, useVariation) {
      let id = null
      if (useVariation) id = workout.variationid
      else id = workout.workoutid

      let workoutType = useVariation
        ? workout.variation_type
        : workout.hasOwnProperty('workout_type')
        ? workout.workout_type
        : workout.type

      let path = '/workouts/start/' + id
      if (workoutType == 'Circuit') path = '/workouts/start/hiit/' + id
      else if (workoutType == 'Hybrid') {
        let xmode = useVariation ? workout.variation_xmode : workout.xmode
        let type = 'rft'
        if (xmode == 'time') type = 'rft'
        else type = xmode

        path = '/workouts/start/' + type + '/' + id
        // emom, rft, amrap
      }

      this.$router.push({
        path: path
      })
    }
  }
}
</script>
